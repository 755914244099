import React, {
  useEffect,
  useState,
  useCallback,
  createContext,
  useContext,
} from "react";

const RecaptchaContext = createContext();

export const RecaptchaProvider = ({ children }) => {
  const [reCaptchaToken, setReCaptchaToken] = useState(null);

  const handleRecaptchaCallback = useCallback((token) => {
    setReCaptchaToken(token);
  }, []);

  useEffect(() => {
    window.handleRecaptchaCallback = handleRecaptchaCallback;
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <RecaptchaContext.Provider value={reCaptchaToken}>
        {children}
      </RecaptchaContext.Provider>
      <div
        className="g-recaptcha"
        data-sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY_INVISIBLE}
        data-callback="handleRecaptchaCallback"
        data-size="invisible"
        style={{ display: "none" }}
      />
    </>
  );
};

export const useRecaptcha = () => {
  const token = useContext(RecaptchaContext);
  const [grecaptcha, setGrecaptcha] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        window.grecaptcha &&
        typeof window.grecaptcha?.execute === "function"
      ) {
        clearInterval(interval);
        setGrecaptcha(window.grecaptcha);
        window.grecaptcha.execute();
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const reset = () => {
    console.log("Reset");
    grecaptcha?.reset();
  };

  const execute = () => {
    console.log("Execute");
    grecaptcha?.execute();
  };

  return { token, reset, execute };
};
