import { Fragment } from "react";
import MainHeader from "./MainHeader";
import { Box } from "@chakra-ui/react";
import UchatButton from "components/Home/UchatButton";

function Layout(props) {
  return (
    <Fragment>
      <MainHeader />
      <Box pt={{ base: "50px", lg: "72px" }}>
        <main>{props.children}</main>
      </Box>
      <UchatButton />
    </Fragment>
  );
}

export default Layout;
