import axios from "axios";
import { format } from "date-fns";
const GOOGLE_ID =
  "AKfycbw2FKaXV95m1ZgOhJewrTVftRslw74QusKPRmSOx8VpD3bCB9gW9HnoRohb0dJnZoay1A";
let ENDPOINT = `https://script.google.com/macros/s/${GOOGLE_ID}/exec`;

const postMember = (data) => {
  const date = format(new Date(), "yyyy/MM/dd HH:mm:ss");
  const postData = JSON.stringify({ date, ...data });
  return axios
    .post(ENDPOINT + "?type=members", postData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

const postPartner = (data) => {
  const date = format(new Date(), "yyyy/MM/dd HH:mm:ss");
  const postData = JSON.stringify({ date, ...data });
  return axios
    .post(ENDPOINT + "?type=partners", postData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

const gasApi = {
  postMember,
  postPartner,
};

export default gasApi;
