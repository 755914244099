import appStore from "../store";
import { Spinner, Flex } from "@chakra-ui/react";

function Loading() {
  const { loading } = appStore;
  return (
    loading && (
      <Flex
        zIndex="9999"
        justify="center"
        align="center"
        pos="absolute"
        w="100%"
        h="100%"
        bgColor="rgba(255, 255, 255, .6)"
      >
        <Spinner />
      </Flex>
    )
  );
}

export default Loading;
