import { toast } from "react-toastify";
import { getUA, deviceType, osName } from "react-device-detect";
import appStore from "../store";
import axios from "axios";

function toastErrorAndReport(msg, type = "error", error_location) {
  if (type == "success") {
    toast.success(msg);
  } else {
    toast.error(msg);
  }

  const { member } = appStore;
  const body = {
    error_title: "Toast Error",
    error_message: msg,
    error_location: error_location,
    platform: "udrive",
    type: "udrive_web_toast_error",
    additional_info: {
      report_user_info: {
        user_id: member?.id,
        user_name: member?.fullname,
        user_phone: member?.phone,
        user_payment_method: member?.payment_method,
        device_info: {
          user_agent: getUA,
          device_type: deviceType,
          os_name: osName,
        },
      },
    },
  };
  axios.post("https://xchat.udrive.city/api/reportBug", body);
}

export default toastErrorAndReport;
