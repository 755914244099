import { createContext, useContext, useEffect, useState } from "react";

const SessionContext = createContext({
  isLoading: true,
  token: "",
  setToken: () => {},
  clearToken: () => {},
});

function useSession() {
  return useContext(SessionContext);
}

export function SessionProvider(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [token, _setToken] = useState();

  function setToken(t) {
    localStorage.setItem("udrive_token", t);
    _setToken(t);
  }

  function clearToken() {
    _setToken(undefined);
    localStorage.removeItem("udrive_token");
  }

  useEffect(() => {
    const token = localStorage.getItem("udrive_token");
    if (token) {
      setToken(token);
    }
    setIsLoading(false);
  }, []);

  return (
    <SessionContext.Provider
      value={{
        isLoading,
        token,
        setToken,
        clearToken,
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
}

export { SessionContext, useSession };
