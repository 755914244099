export const DEFAULT_DELIVERY_ZONE_LIST = [
  { id: 4, level_1: "台中市" },
  { id: 56, level_1: "台北市", dev_id: 1 },
  { id: 89, level_1: "台南市" },
  { id: 174, level_1: "宜蘭縣" },
  { id: 7, level_1: "苗栗縣" },
  { id: 2, level_1: "桃園市", dev_id: 2 },
  { id: 90, level_1: "高雄市" },
  { id: 468, level_1: "基隆市" },
  { id: 87, level_1: "雲林縣" },
  { id: 60, level_1: "新北市", dev_id: 7 },
  { id: 470, level_1: "新竹市" },
  { id: 6, level_1: "新竹縣" },
  { id: 88, level_1: "嘉義縣" },
  { id: 86, level_1: "彰化縣" },
];

// 先定義完整的對照表作為參考
export const CITY_MAPPING = {
  北部: ["臺北", "台北", "新北", "基隆", "新竹", "桃園", "宜蘭"],
  中部: ["臺中", "台中", "苗栗", "彰化", "南投"],
  南部: ["雲林", "高雄", "臺南", "台南", "嘉義", "屏東"],
  東部: ["花蓮", "臺東", "台東"],
};

export const getCityRegion = (cityName) => {
  if (!cityName) return null;

  // 移除「市」、「縣」後綴
  const cleanCityName = cityName.replace(/(市|縣)$/, "");

  // 遍歷每個區域，檢查是否包含該城市名稱
  for (const [region, cities] of Object.entries(CITY_MAPPING)) {
    if (cities.some((city) => cleanCityName.includes(city))) {
      return region;
    }
  }

  return null;
};
