import resso from "resso";
import { addDays, set, differenceInHours } from "date-fns";
import { DateObject } from "react-multi-date-picker";
const now = new Date();
const nextWorkDay = new Date(
  set(addDays(now, 1), {
    hours: 9,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  })
);

const diffInHours = differenceInHours(nextWorkDay, now);

// 如果現在時間超過 21:00 ，則預設租借時間為後天
const addDaysNum = diffInHours < 12 ? 2 : 1;

// 如果現在時間是 2/3 之後，則預設 initStartTime 為 2/15
export const initStartTime = new DateObject(
  now > new Date("2024/02/03") && now < new Date("2024/02/15")
    ? new Date("2024/02/15 09:00:00")
    : set(addDays(now, addDaysNum + 1), {
        hours: 9,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      })
);

export const initEndTime = new DateObject(
  now > new Date("2024/02/03") && now < new Date("2024/02/15")
    ? new Date("2024/02/17 09:00:00")
    : set(addDays(now, addDaysNum + 3), {
        hours: 9,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      })
);

function getCookie(name) {
  if (typeof document === "undefined" || typeof document.cookie !== "string")
    return null;
  let cookies = document.cookie.split("; ");
  for (let i = 0; i < cookies.length; i++) {
    let [cookieName, cookieValue] = cookies[i].split("=");
    if (cookieName === name) {
      return cookieValue;
    }
  }
  return null; // 如果沒找到指定名稱的cookie，返回null
}

const appStore = resso({
  member: {},
  loading: false,
  cardData: {},
  isRegisterModalVisible: false,

  isInvitedModalVisible: false,
  isGetInviteCouponModalVisible: false,

  isShowMyOrderTooltip: true,

  // 是否顯示顏色選擇
  isShowColorChoose: false,

  // modal sheet control
  isModalSheetOpen: false,
  modalSheetControls: null,

  isOpenBindAECardModal: false,

  // user settings
  // 右上篩選器
  isCarListFetching: true,
  modelsFilter: {
    IONIQ6: true,
    IX1: true,
    ModelS: true,
    ModelY: true,
    ModelX: true,
    Model3: true,
  },
  sortBy: "low",
  colorSelected: {
    white: false,
    black: false,
    red: false,
    silver: false,
    pink: false,
    quick_silver: false,
    blue: false,
    sky_blue: false,
  },
  tempSortBy: "low",
  tempColorSelected: {
    white: false,
    black: false,
    red: false,
    silver: false,
    pink: false,
    quick_silver: false,
    blue: false,
    sky_blue: false,
  },
  orders: [], // 全部的訂單
  cars: [], // 全部有的車
  top_models: null, // 熱門車款
  carsToTwoLevel2: [],
  deliveryZones: [], // 全部的配送區域（包含車行未設定的）
  configs: [], // 全部車子之車行設定

  cityUnion: {}, // 全部車行有設定的城市
  latestOrderTimeUnion: 0, //全部車行中最新可下訂時間
  timeOptsUnion: [], // 全部車行中可選擇的時間

  selectedCity: "", // 使用者設定後的的城市
  selectedZone: "", // 使用者設定後的的區域
  selectedStation: "", // 使用者設定後的的捷運站
  selectedZoneId: "", // 以上 City, Zone, Station 合併的 id，為後端給予
  tempSelectedCity: "", // 使用者設定前的的城市
  tempSelectedZone: "", // 使用者設定前的的區域
  tempSelectedStation: "", // 使用者設定前的的捷運站
  tempSelectedZoneId: "", // 以上 City, Zone, Station 合併的 id，為後端給予

  selectedReturnCity: "",
  selectedReturnZone: "",
  selectedReturnStation: "",
  selectedReturnZoneId: "",
  tempSelectedReturnCity: "",
  tempSelectedReturnZone: "",
  tempSelectedReturnStation: "",
  tempSelectedReturnZoneId: "",

  selectedStation: {},

  address: "", // 使用者設定的地址

  isSearchAirport: false,
  isAirport: 0, // 使用者是否選擇機場
  isAirportReturn: 0,
  isSameAddress: true, // 使用者是否選擇同一地址
  campaign: "",

  // 關於行事曆
  selectedDays: [initStartTime, initEndTime],
  selectedStartClock: initStartTime.format("HH:mm"),
  selectedEndClock: initEndTime.format("HH:mm"),
  tempSelectedDays: [initStartTime, initEndTime],
  tempSelectedStartClock: initStartTime.format("HH:mm"),
  tempSelectedEndClock: initEndTime.format("HH:mm"),
  selectedCarIds: [],
  isOpenCitySelect: false,
  isOpenReturnCitySelect: false,
  tempSelectedCarIds: [],
  filterOrders: [], // 篩選後的訂單
  allRangeTimes: [],
  zoneOpenSt: "",
  zoneOpenEd: "",
  zoneDetail: {},
  rentType: "station",

  // CarList
  avaiableCarGroups: [],

  // Uchat測試
  isTestTeam: false,
  isHelperOpen: false,

  // order page
  availableCarIds: [],

  // new year
  amount: 0,
  mileageDiscount: 0,
});

export default appStore;
