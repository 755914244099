import {
  Text,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import RegisterForm from "./RegisterForm";
import appStore from "../../store";
import { useTranslation } from "next-i18next";
import GCPImage from "components/GCPImage";

const RegisterModal = () => {
  const { t } = useTranslation("common");
  const { isRegisterModalVisible: isOpen } = appStore;
  const onClose = () => (appStore.isRegisterModalVisible = false);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        w="95%"
        bgColor="#363740"
        pt="10px"
        pb="40px"
        rounded="20px"
      >
        <ModalHeader></ModalHeader>
        <ModalCloseButton color="#fff" />
        <ModalBody textAlign="center" color="#DEE3F0">
          <Box
            w="25%"
            pos="absolute"
            left="0px"
            right="0px"
            top="0"
            transform="translateY(-60%)"
            mx="auto"
          >
            <GCPImage src="/images/icons/gift.png" alt="gift" />
          </Box>
          <Text fontSize="20px" fontWeight="bold" color="#DEE3F0">
            {t("beMember")}
            <br />
            {t("discountText")}
          </Text>
          <RegisterForm />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RegisterModal;
