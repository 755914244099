import { Button } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";

function MainButton({ hasIcon, isLoading, white, children, ...props }) {
  return (
    <Button
      size={{ base: "md", md: "xl" }}
      fontSize={{ base: "xs", md: "15px" }}
      rounded="20px"
      px="20px"
      py="15px"
      borderRadius="0"
      isLoading={isLoading}
      borderWidth="1px"
      borderColor="black"
      _hover={{
        background: white ? "black" : "white",
        color: white ? "white" : "black",
      }}
      color={white ? "black" : "white"}
      bg={white ? "white" : "black"}
      rightIcon={hasIcon ? <ArrowForwardIcon fontSize="18px" /> : ""}
      {...props}
    >
      {children}
    </Button>
  );
}

export default MainButton;
