import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: "Noto Sans TC, sans-serif",
    body: "Noto Sans TC, sans-serif",
  },
  components: {
    Radio: {
      baseStyle: {
        control: {
          borderColor: "#28292B",
          _checked: {
            bg: "#28292B",
            borderColor: "#28292B",
            boxShadow: "none",
          },
          _focus: {
            boxShadow: "none",
          },
        },
      },
    },
  },
});

export default theme;
