import { useRouter } from "next/router";
import { Box, Text, Flex } from "@chakra-ui/react";
import { useWindowDimensions } from "../../lib/windows";

const SwitchLang = () => {
  const router = useRouter();
  const { locale } = router;
  const { width, height } = useWindowDimensions();

  const switchLang = async (value) => {
    await router.push(router.pathname, router.asPath, { locale: value });
  };

  return (
    <Flex
      bg="white"
      boxShadow=" 0 4px 4px 0 rgba(0, 0, 0, .25)"
      h="30px"
      alignItems="center"
      fontSize="13px"
      fontWeight="bold"
      rounded="5px 5px 5px 5px"
      pt="3px"
      color="black"
      mt="15px"
    >
      <Flex justify="end" pr="10px" w="40px">
        <Text
          opacity={locale === "zh-TW" ? "1" : "0.3"}
          cursor="pointer"
          onClick={() => {
            switchLang("zh-TW");
          }}
        >
          中
        </Text>
      </Flex>
      <Text>/</Text>
      <Text
        pl="10px"
        opacity={locale === "en" ? "1" : "0.3"}
        cursor="pointer"
        onClick={() => {
          switchLang("en");
        }}
        w="40px"
      >
        EN
      </Text>
      <Text>/</Text>
      <Text
        pl="10px"
        opacity={locale === "jp" ? "1" : "0.3"}
        cursor="pointer"
        onClick={() => {
          switchLang("jp");
        }}
        w="60px"
      >
        日本語
      </Text>
    </Flex>
  );
};

export default SwitchLang;
