import { addDays } from "date-fns";
import { useSession } from "hooks/useSession";
import { DEFAULT_DELIVERY_ZONE_LIST } from "lib/delivery";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { DateObject } from "react-multi-date-picker";
import { toast } from "react-toastify";
import appStore from "store";
import api from "store/api";

export default function QueryStringHandler() {
  const router = useRouter();
  const session = useSession();

  // 幫 partner or referral 加上 utm
  useEffect(() => {
    if (router.query?.partner || router.query?.referral) {
      if (
        !router.query?.utm_campaign &&
        !router.query?.utm_source &&
        !router.query?.utm_medium
      ) {
        const code = router.query?.partner || router.query?.referral;
        let pathname = router.pathname;

        if (router.query?.pid) {
          pathname = `/blog/${router.query?.pid}`;
        }
        delete router.query["pid"];
        router.push({
          pathname: pathname,
          query: {
            ...router.query,
            utm_campaign: "referral",
            utm_source: `referral_${code}`,
            utm_medium: "none",
          },
        });
      }
    } else {
      if (router.query?.pid) {
        let pathname = `/blog/${router.query?.pid}`;

        delete router.query["pid"];
        router.push({
          pathname: pathname,
          query: router.query,
        });
      }
    }
  }, [router.isReady]);

  useEffect(() => {
    if (session.isLoading) return;

    let isUchat = false;
    if (typeof window !== "undefined") {
      if (window?.location?.href?.includes("uchat")) {
        isUchat = true;
      }
    }
    const isHelper = router.query?.hasOwnProperty("helper");
    if (isUchat && !isHelper) {
      if (session.token) {
        appStore.isHelperOpen = true;
        // HOTFIX: 簡訊網址與後面文字連在一起，會導致開不了uchat
        const url = window?.location?.href.split("uchat")[0];
        router.push(url);
        // delete router.query["uchat"];
        // router.replace({
        //   query: router.query,
        // });
        return;
      } else {
        const query = { ...router.query, helper: true };
        router.push({
          pathname: "/login",
          query,
        });
        return;
      }
    }

    if (router?.query?.referral_code) {
      if (session.token) {
        if (router.pathname === "/login") {
          router.push({
            pathname: "/",
            query: router.query,
          });
          return;
        }
        const data = {
          referral_code: router.query.referral_code,
        };
        api
          .useReferralCode(data)
          .then((resp) => {
            delete router.query["referral_code"];
            router.push({
              query: router.query,
            });
            appStore.isInvitedModalVisible = true;
          })
          .catch((e) => {
            toast.error(e.response.data.message);
            delete router.query["referral_code"];
            router.push({
              query: router.query,
            });
          });
      }
    }

    if (router.query?.coupon) {
      if (session.token) {
        appStore.isRegisterModalVisible = false;
        const data = { code: router.query.coupon };
        api.receiveCoupon(data).then((resp) => {
          if (resp.status === 200) {
            toast.success("領取優惠成功 Get coupon successfully");
          }
          delete router.query["coupon"];
          router.push({
            query: router.query,
          });
        });
      } else {
        appStore.isRegisterModalVisible = true;
      }
    }

    if (router.query?.zone) {
      let _deliveryZones = [];
      api.getDeliveryZone().then((resp) => {
        _deliveryZones = resp.data.data;

        let defaultSelectedCity = "";
        switch (router.query?.zone) {
          case "taipei":
            defaultSelectedCity = "台北市";
            break;
          case "taichung":
            defaultSelectedCity = "台中市";
            break;
          case "taoyuan":
            defaultSelectedCity = "桃園市";
            break;
          case "hsinchu":
            defaultSelectedCity = "新竹市";
            break;
          case "tainan":
            defaultSelectedCity = "台南市";
            break;
          case "kaohsiung":
            defaultSelectedCity = "高雄市";
            break;
          default:
            defaultSelectedCity = "台北市";
        }
        const defaultZoneData = DEFAULT_DELIVERY_ZONE_LIST.find(
          (item) => item.level_1 === defaultSelectedCity
        );

        let zone = {};
        zone = _deliveryZones.find(
          (item) => item.level_1 === defaultSelectedCity
        );

        if (defaultZoneData) {
          const defaultZone = _deliveryZones.find((item) => {
            if (window?.location?.href?.includes("dev")) {
              return item.id === defaultZoneData.dev_id;
            } else {
              return item.id === defaultZoneData.id;
            }
          });
          if (defaultZone) {
            zone = defaultZone;
          }
        }

        appStore.selectedZoneId = zone.id;
        appStore.selectedCity = zone.level_1;
        appStore.selectedZone = zone.level_2;
        appStore.selectedStation = zone.level_3;
        appStore.selectedReturnCity = zone.level_1;
        appStore.selectedReturnZone = zone.level_2;
        appStore.selectedReturnStation = zone.level_3;
        appStore.selectedReturnZoneId = zone.id;
        appStore.tempSelectedZoneId = zone.id;
        appStore.tempSelectedCity = zone.level_1;
        appStore.tempSelectedZone = zone.level_2;
        appStore.tempSelectedStation = zone.level_3;
        appStore.tempSelectedReturnZoneId = zone.id;
        appStore.tempSelectedReturnCity = zone.level_1;
        appStore.tempSelectedReturnZone = zone.level_2;
        appStore.tempSelectedReturnStation = zone.level_3;

        delete router.query["zone"];
        router.push({
          query: router.query,
        });
      });
    }

    if (router.query?.start_date) {
      let selectedDays = [];
      if (router.query?.end_date) {
        selectedDays = [
          new DateObject(router.query.start_date),
          new DateObject(router.query.end_date),
        ];
      } else {
        selectedDays = [
          new DateObject(router.query.start_date),
          new DateObject(router.query.start_date).add(2, "day"),
        ];
      }

      appStore.selectedDays = selectedDays;
      appStore.tempSelectedDays = selectedDays;

      delete router.query["start_date"];
      delete router.query["end_date"];
      router.push({
        query: router.query,
      });
    }
  }, [router, session.isLoading]);
  return null;
}
