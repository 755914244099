// 參考 https://github.com/vercel/next.js/blob/canary/packages/third-parties/src/google/gtm.tsx
// 設定 User Event 的
export const sendAppierEvent = (event, data) => {
  if (window.appier) {
    // console.log("sendAppierEvent", event, data);
    window.appier("event", event, data);
  } else {
    console.warn("Appier 未載入");
  }
};

// 設定 User Profile 的
export const sendAppierIdentify = (data) => {
  if (window.appier) {
    window.appier("identify", data);
  } else {
    console.warn("Appier 未載入");
  }
};

// 某些時機要同時 update Profile 與送出 Event
// Case 維護在 https://docs.google.com/spreadsheets/d/1_1HbPEzNVNRSN7omXoo_PnIo2cSh8St3M8la0ZMAUlQ/edit?gid=417762430#gid=417762430
export const sendAppierEventAndIdentify = (event, data) => {
  switch (event) {
    case "login":
      sendAppierEvent(event, data);
      sendAppierIdentify({
        user_id: data.user_id,
        phoneNo: data.phoneNo,
        profile_update_date: new Date(),
      });
      break;
    case "payment_info_added":
      sendAppierEvent(event, data);
      sendAppierIdentify({
        user_id: data.user_id,
        phoneNo: data.phoneNo,
        payment_method1: data.payment_method1,
        profile_update_date: new Date(),
      });
      break;
    case "invoice_info_added":
      sendAppierEvent(event, data);
      sendAppierIdentify({
        user_id: data.user_id,
        phoneNo: data.phoneNo,
        type: data.type,
        info: data.info,
        profile_update_date: new Date(),
      });
      break;
    case "submit_additional_info":
      sendAppierEvent(event, data);
      sendAppierIdentify({
        user_id: data.user_id,
        phoneNo: data.phoneNo,
        user_name: data.user_name,
        profile_update_date: new Date(),
      });
      break;
    default:
      break;
  }
};
