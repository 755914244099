import { ChakraProvider } from "@chakra-ui/react";
import ErrorBoundary from "components/ErrorBoundary";
import AuthWrapper from "containers/AuthWrapper";
import TrackingHandler from "containers/TrackingHandler";
import { RecaptchaProvider } from "hooks/useRecaptcha";
import { SessionProvider } from "hooks/useSession";
import { appWithTranslation, useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Head from "next/head";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/pagination";
import nextI18NextConfig from "../../next-i18next.config";
import Layout from "../components/Layout/Layout";
import RegisterModal from "../components/Layout/RegisterModal";
import Loading from "../components/Loading";
import PageLoading from "../components/PageLoading";
import "../styles/globals.css";
import "../styles/wp-blog.css";
import QueryStringHandler from "containers/QueryStringHandler";
import { useEffect } from "react";
import theme from "lib/theme";
import InviteModal from "components/Layout/InviteModal";

function MyApp({ Component, pageProps }) {
  const { t } = useTranslation("common");

  useEffect(() => {
    if (window.taq) {
      taq("track", "CZq5iOR-neL2", "ViewContent");
    }
  }, []);
  
  return (
    <>
      <Head>
        <title key="title">{t("homePageTitle")}</title>
        <meta name="app-version" content="3.0.8v202308221754" />
        <meta
          key="description"
          name="description"
          content={t("homePageDesc")}
        />
        <meta key="og_title" property="og:title" content={t("homePageTitle")} />
        <meta
          key="og_image"
          property="og:image"
          content="https://www.udrive.city/share.jpg"
        />
        <meta
          key="og_url"
          property="og:url"
          content="https://www.udrive.city/"
        />
        <meta key="og_type" property="og:type" content="website" />
        <meta
          key="og_description"
          property="og:description"
          content={t("homePageDesc")}
        />
        <link
          rel="icon"
          href="https://uspace.city/wp-content/uploads/2019/11/cropped-工作區域-21-1-32x32.png"
          sizes="32x32"
        />
        <meta
          name="facebook-domain-verification"
          content="qjkv3gxq03imc179qa0dkezwl94waf"
        />
      </Head>
      <ErrorBoundary>
        <TrackingHandler />
        <SessionProvider>
          <AuthWrapper>
            <ChakraProvider theme={theme}>
              <QueryStringHandler />
              <ToastContainer
                position="top-center"
                autoClose={2500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <RecaptchaProvider>
                {/* <ModalSheetLayout> */}
                <Layout>
                  <Component {...pageProps} />
                </Layout>
                <RegisterModal />
                <InviteModal />
                <Loading />
                <PageLoading />
                {/* </ModalSheetLayout> */}
              </RecaptchaProvider>
            </ChakraProvider>
          </AuthWrapper>
        </SessionProvider>
      </ErrorBoundary>
    </>
  );
}

export async function getServerSideProps(context) {
  const { res, locale } = context;
  res.setHeader("Cache-Control", "no-store");
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
}

export default appWithTranslation(MyApp, nextI18NextConfig);
