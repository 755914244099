import axios from "axios";
import { toast } from "react-toastify";
import appStore from "store";
import { getUA, deviceType, osName } from "react-device-detect";
import { sendAppierEvent, sendAppierEventAndIdentify } from "lib/appier";
import { format } from "date-fns";
import { jwtDecode } from "jwt-decode";

let ENDPOINT = process.env.NEXT_PUBLIC_ENDPOINT;
const UPLOADIMAGEPOINT = process.env.NEXT_PUBLIC_UPLOADIMAGEPOINT;
const BLOG_ENDPOINT = process.env.NEXT_PUBLIC_BLOGENDPOINT;
const REQUEST_PASSCODE_ENDPOINT =
  process.env.NEXT_PUBLIC_REQUEST_PASSCODE_ENDPOINT;
if (typeof window !== "undefined") {
  if (window?.location?.href?.includes("dev")) {
    ENDPOINT = process.env.NEXT_PUBLIC_DEV_ENDPOINT;
  }
}

const WP_POST_BLACK_LIST = [1200];

const createApi = () => {
  const apiClient = axios.create({
    baseURL: ENDPOINT,
  });
  return apiClient;
};

const createApiWithAuth = () => {
  const token = window.localStorage.getItem("udrive_token");
  const apiClient = axios.create({
    baseURL: ENDPOINT,
  });
  apiClient.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  return apiClient;
};

const fetchAPI = async (fetch, handleError) => {
  let isOk = false;
  let resp = {};
  const { member } = appStore;
  try {
    resp = await fetch();
    isOk = true;
  } catch (error) {
    const msg =
      error.response?.data?.message || error.response?.data?.error || "";

    if (handleError) {
      handleError(error);
    } else {
      switch (msg) {
        case "insufficient permission":
          break;
        default:
          toast.error(msg || "系統忙線中，請稍候");
          break;
      }
    }
    // 白名單
    const BLACK_LIST = [
      "https://api.uspace.city/api/v1/members/me",
      "https://api.uspace.city/api/v1/orders/me",
      "https://api.uspace.city/api/rental_cars/owners/cars",

      "https://udrive-blog-api.uspace.city",
    ];

    const request_data = error?.config?.data
      ? JSON.parse(error.config.data)?.google_recaptcha_token
        ? JSON.stringify({
            ...JSON.parse(error?.config?.data),
            google_recaptcha_token: "**********",
          })
        : error.config.data
      : "";

    const body = {
      error_title: "API Error",
      error_message: msg,
      error_location: error?.response?.config?.url,
      platform: "udrive",
      type: "udrive_api",
      additional_info: {
        report_user_info: {
          user_id: member?.id,
          user_name: member?.fullname,
          user_phone: member?.phone,
          user_payment_method: member?.payment_method,
          device_info: {
            user_agent: getUA,
            device_type: deviceType,
            os_name: osName,
          },
        },
        api_info: {
          method: error.config.method,
          status: error.response?.status,
          request_data: request_data,
          response_data: error.response?.data,
        },
      },
    };

    try {
      let isBlack = false;
      // 如果在 BLACK_LIST 內，就不會發送錯誤訊息
      BLACK_LIST.forEach(async (url) => {
        if (error?.response?.config?.url?.includes(url)) {
          isBlack = true;
        }
      });
      if (!isBlack) {
        await axios.post("https://xchat.udrive.city/api/reportBug", body);
      }
    } catch (e) {
      console.log(e);
    }
  }

  resp.isOk = isOk;
  return resp;
};

// Login
// {
//   "country_code": "886",
//   "phone_number":"0912345678"
// }

// 2.6 取得驗證碼
const getPassCode = async (data) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(
    async () => {
      const url = `${REQUEST_PASSCODE_ENDPOINT}`;
      const resp = await apiClient.post(url, data);
      toast.success("簡訊送出成功！");
      sendAppierEvent("passcode_requested", {
        phoneNo: data.phone_number,
        country_code: data.country_code,
      });
      return resp;
    },
    (error) => {
      const msg = error.response?.data?.message;
      if (msg === "smsCoolDown") {
        const s = error.response?.data?.cooldown;
        toast.warn(`短期間無法再次請求驗證碼，請稍等 ${s} 秒再嘗試！`);
      } else if (msg === "bannedAccount") {
        toast.error("您已申請刪除帳戶");
      } else {
        toast.error(msg || "系統忙線中，請稍候");
      }
    }
  );
  return result;
};

// {
//   "phone_number":"0912345678",
//   "pass_code":"1234",
//   "country_code": "886"
// }

// 2.2 取得 token
const getToken = async ({
  phoneNumber,
  passCode,
  countryCode,
  registrationSource,
}) => {
  const apiClient = createApiWithAuth();
  const data = {
    country_code: countryCode,
    phone_number: phoneNumber,
    pass_code: passCode,
    registration_platform: "udrive_web",
    registration_source: registrationSource,
  };
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v2/login`;

    return apiClient
      .post(url, data)
      .then(async (resp) => {
        toast.success("Verify Success! 手機驗證成功！");
        sendAppierEventAndIdentify("login", {
          user_id: resp.data.member_id,
          phoneNo: phoneNumber,
          country_code: countryCode,
          profile_update_date: new Date(),
        });
        return resp;
      })
      .catch((error) => {
        toast.error("Verify Failed, Plase try again. 驗證失敗，請再試一次！");
        sendAppierEvent("login_failed", {
          phoneNo: phoneNumber,
          country_code: countryCode,
          pass_code: passCode,
        });
      });
  });

  return result;
};

// Order
// Get Car List -> Get Amount -> Book Car -> Pay Car Order

const getCars = async () => {
  const apiClient = createApi();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/rental_cars/cars`;
    return apiClient.get(url);
  });
  return result;
};

const getBestMatchCar = async (data) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/rental_cars/cars/revenue`;
    return apiClient.post(url, data);
  });
  return result;
};

const getOrders = async () => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/rental_cars/orders`;
    return apiClient.get(url);
  });
  return result;
};

// FIXME: 這個 API 跟 getGroupPrice 重複了，要確認是否可以刪除？ Doppler
const getAmount = async (data) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/rental_cars/cars/group_price`;
    return apiClient.post(url, data);
  });
  return result;
};

const getGroupPrice = async (data, locale = "zh-tw") => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/rental_cars/cars/group_price?lang=${locale}`;
    return apiClient.post(url, data);
  });
  return result;
};

const bookCar = async (data) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/rental_cars/orders`;
    return apiClient.post(url, data);
  });
  return result;
};

const getMyRecentOrder = async () => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/rental_cars/orders/me`;
    return apiClient.get(url);
  });
  return result;
};

const getMyOrder = async (order_id) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/rental_cars/orders/${order_id}`;
    return apiClient.get(url);
  });
  return result;
};

const updateMyOrder = async (order_id, orderData) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/rental_cars/orders/${order_id}/pickup`;
    return apiClient.put(url, orderData);
  });
  return result;
};

const getMyContract = async (order_id) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/rental_cars/orders/${order_id}/pdf`;
    return apiClient.get(url);
  });
  return result;
};

const getMyIdStatus = async () => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/members/me/identification`;
    return apiClient.get(url);
  });
  return result;
};

const updateIdentification = async (data) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/members/me/identification`;
    return apiClient.post(url, data);
  });
  return result;
};

const payCarOrder = async (order_id) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/rental_cars/orders/me`;
    const data = {
      order_id,
      payment_type: "start_payment",
    };
    return apiClient.put(url, data);
  });
  return result;
};

const payFinalCarOrder = async (order_id) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/rental_cars/orders/me`;
    const data = {
      order_id,
      payment_type: "end_payment",
    };
    return apiClient.put(url, data);
  });
  return result;
};

const repayCarOrder = async (order_id, payment_id) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/rental_cars/orders/${order_id}/repay/${payment_id}`;
    const data = {
      payment_type: "start_payment",
    };
    return apiClient.put(url, data);
  });
  return result;
};

const cancelCarOrder = async (order_id) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/rental_cars/orders/${order_id}/cancel`;
    const data = {};
    return apiClient.put(url, data);
  });
  return result;
};

const getUnpaidFees = async () => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/rental_cars/orders/unpaid_fees`;
    return apiClient.get(url);
  });
  return result;
};

const payUnpaidFees = async (order_id) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/rental_cars/orders/${order_id}/fees`;
    const data = {};
    return apiClient.put(url, data);
  });
  return result;
};

const getMyHistory = async () => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/rental_cars/orders/history`;
    return apiClient.get(url);
  });
  return result;
};

// Member Data
const getMyProfile = async () => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/members/me`;
    return apiClient.get(url);
  });
  return result;
};

const updateMyProfile = async (data) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/members/me`;
    return apiClient.put(url, data);
  });

  if (result.isOk) {
    try {
      // 解 JWT token 取得 user_id, phone
      // 從 API 呼叫取得當時 request 的 token
      const token = result.config.headers.Authorization.split(" ")[1];
      const decoded = jwtDecode(token);
      const user_id = decoded.uuid;
      const phoneNo = decoded.phone;
      let trackingObj = {};
      // 因為欄位跟事件名稱不一樣要特別 Handle 轉換，
      data.fullname && (trackingObj.user_name = data.fullname);
      data.email && (trackingObj.email = data.email);
      sendAppierEvent("submit_additional_info", {
        user_id,
        phoneNo,
        ...trackingObj,
      });
      if (data?.invoice) {
        sendAppierEvent("invoice_info_added", {
          // FIXME: 還在想怎麼比較乾淨取得 email
          user_id,
          phoneNo,
          type: data.invoice.type,
          info: `${data.invoice.info_1},${data.invoice.info_2}`,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  return result;
};

const setPaymentMethod = async (method) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/members/me/payment_method/${method}`;
    const data = {};
    return apiClient.put(url, data);
  });
  return result;
};

const addCard = async (data) => {
  try {
    const apiClient = createApiWithAuth();
    const url = `${ENDPOINT}/api/v1/cards?order_type=rental_car`;
    const fetchApi = () => apiClient.post(url, data);
    const setCardAsDefault = (card_id) =>
      apiClient.put(`${ENDPOINT}/api/v1/cards/${card_id}`, {});
    const setPaymentMethod = (method) =>
      apiClient.put(
        `${ENDPOINT}/api/v1/members/me/payment_method/${method}`,
        {}
      );
    const response = await fetchApi();
    if (response.status === 200) {
      await setCardAsDefault(response.data.id);
      await setPaymentMethod("card");
    } else {
      alert(response.message);
    }
    return response;
  } catch (error) {
    return error.response;
  }
};

const addNewCard = async (data) => {
  const apiClient = createApiWithAuth();
  const url = `${ENDPOINT}/api/v1/cards`;
  const fetchApi = () => apiClient.post(url, data);
  const setCardAsDefault = (card_id) =>
    apiClient.put(`${ENDPOINT}/api/v1/cards/${card_id}`, {});
  const setPaymentMethod = (method) =>
    apiClient.put(`${ENDPOINT}/api/v1/members/me/payment_method/${method}`, {});
  const response = await fetchApi();
  if (response.status === 200) {
    await setCardAsDefault(response.data.id);
    await setPaymentMethod("card");
  } else {
    alert(response.message);
  }
  return response;
};

const deleteCard = async (card_id) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/cards/${card_id}`;
    const data = {};
    return apiClient.delete(url, data);
  });
  return result;
};

const getPosts = async ({ page = 1, term_id }) => {
  const result = await fetchAPI(() => {
    let url = "";
    if (term_id && term_id !== 0) {
      url = `${BLOG_ENDPOINT}/posts?term_id=${term_id}&page=${page}`;
    } else {
      url = `${BLOG_ENDPOINT}/posts?page=${page}`;
    }
    return axios.get(url);
  });

  // 去除文章 id 在 WP_POST_BLACK_LIST 內的文章
  result.data.data = result.data.data.filter((post) => {
    return !WP_POST_BLACK_LIST.includes(post.id);
  });
  return result;
};

const getPostTerms = async () => {
  const result = await fetchAPI(() => {
    const url = `${BLOG_ENDPOINT}/terms`;
    return axios.get(url);
  });
  return result;
};

const getPostDetail = async (post_id) => {
  if (!post_id || post_id == "undefined") return;
  const result = await fetchAPI(() => {
    const url = `${BLOG_ENDPOINT}/posts/${post_id}`;
    return axios.get(url);
  });
  return result;
};

// LINE PAY

const getLinepayUrl = async (path, type = "") => {
  const apiClient = createApiWithAuth();
  const location = window.location.origin;
  const callback_url = encodeURIComponent(location + path);

  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/linepay/get_bind_url?order_type=${type}&callback_url=${callback_url}`;
    return apiClient.get(url);
  });
  return result;
};

const getLinepayRegKey = async (type = "") => {
  try {
    const apiClient = createApiWithAuth();
    const url = `${ENDPOINT}/api/v1/linepay/get_regkey?order_type=${type}`;
    const fetchApi = () => apiClient.get(url);
    const result = await fetchApi();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const deleteLinepay = async () => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/linepay`;
    return apiClient.delete(url);
  });
  return result;
};

// 街口
const getJkopayUrl = async (path) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/jkopay/get_bind_url`;
    const data = {
      type: "web",
      callback_url: "http://www.udrive.city" + path,
    };
    return apiClient.post(url, data);
  });
  return result;
};

const deleteJkopayRedKey = async () => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/jkopay`;
    return apiClient.delete(url);
  });
  return result;
};

// 上傳圖片
const updateImage = async (type, data) => {
  const apiClient = createApiWithAuth();
  const url = `${UPLOADIMAGEPOINT}/${type}`;
  const fetchApi = () => apiClient.post(url, data);
  const response = await toast.promise(fetchApi, {
    pending: "處理中，請稍候...",
    success: "上傳圖片成功 👌",
    error: "上傳圖片失敗",
  });
  return response;
};

// 車主自營運用
const getMyCars = async () => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(
    () => {
      const url = `${ENDPOINT}/api/rental_cars/owners/cars`;
      return apiClient.get(url);
    },
    () => {}
  );
  return result;
};
/*
|invoice| self | full | type                  | description        |
| ----- | ---- | ---- | --------------------- | ------------------ |
| ----- 租金 ------------------------------------------------------ |
| O     |  80% |  60% | rent                  | 租金                |
| O     |  80% |  60% | extend_rent           | 延長租金             |
| O     |  80% |  60% | overtime_rent         | 逾時租金            |
| O     |  80% |  60% | operating_loss_fee    | 營業損失（原價算）    |
| ----- 營運成本 -------------------------------------------------- |
| O     | 100% |   0% | mileage               | 里程費              |
| O     | 100% |   0% | overtime_fee          | 逾時罰金            |
| O     | 100% |   0% | scheduling_fee        | 72小時修改調度費      |
| O     | 100% |   0% | supercharger_idle_fee | 超級充電站超時佔用費  |
| O     | 100% |   0% | low_battery_fee       | 低電量罰金           |
| O     | 100% |   0% | cleaning_fee          | 清潔費              |
| ----- 代收代付 --------------------------------------------------- |
| X     | 100% |   0% | etc_fee               | ETC/過路費          |
| X     | 100% |   0% | parking_fee           | 停車費              |
| X     | 100% |   0% | traffic_ticket        | 交通罰單            |
| X     |   0% |   0% | other_fee             | 其他費用            |
| X     |   0% |   0% | deposit               | 押金                |
*/
const getMyCarsDashbaord = async () => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/rental_cars/owners/dashboard`;
    return apiClient.get(url);
  });
  return result;
};
const getMyCarsOrders = async (order_type, page = 1) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/rental_cars/owners/orders?order_type=${order_type}&page=${page}`;
    return apiClient.get(url);
  });
  return result;
};

const getPlaceAutoComplete = async ({ input }) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/map/place_autocomplete?input=${input}`;
    return apiClient.get(url);
  });
  return result;
};

const getCouponData = async (data) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/rental_cars/orders/coupon`;
    return apiClient.post(url, data);
  });
  return result;
};

const getCountry = async () => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/rental_cars/orders`;
    return apiClient.get(url);
  });
  return result;
};

const getAllOwnerSetting = async () => {
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/rental_cars/service_locations`;
    return axios.get(url);
  });
  return result;
};
const getDeliveryZone = async () => {
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/rental_cars/delivery_zones`;
    return axios.get(url);
  });
  return result;
};

const removePlate = async (data) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/members/me/plate`;
    return apiClient.post(url, data);
  });
  return result;
};

const getCouponList = async () => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/rental_cars/coupons`;
    return apiClient.get(url);
  });
  return result;
};

const receiveCoupon = async (data) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/rental_cars/coupons`;
    return apiClient.post(url, data);
  });
  return result;
};

const createOwnerUse = async (data) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/rental_cars/owners/orders`;
    return apiClient.post(url, data);
  });
  return result;
};

const getOwnerUseRecord = async () => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/rental_cars/owners/maintenance_periods`;
    return apiClient.get(url);
  });
  return result;
};
const deleteOwnerUseRecord = async (data) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/rental_cars/owners/maintenance_periods/${data.period_id}`;
    return apiClient.delete(url);
  });
  return result;
};

const getBannerList = async (locale = "zh-tw") => {
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/banners?app_name=udrive&lang=${locale}`;
    return axios.get(url);
  });
  return result;
};

const bindCardWith3D = async (data) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/cards/3ds?order_type=rental_car`;
    return apiClient.post(url, data);
  });
  return result;
};

const verifyIdentification = async (type) => {
  const apiClient = createApiWithAuth();
  const url = `${ENDPOINT}/api/v1/members/me/identification/${type}`;
  return apiClient.post(url, {});
};

const updateOcrData = async (data) => {
  const apiClient = createApiWithAuth();
  const result = await fetchAPI(() => {
    const url = `${ENDPOINT}/api/v1/members/me/identification/ocr`;
    return apiClient.post(url, data);
  });
  return result;
};

const exchangeTempToken = async (data) => {
  const apiClient = createApiWithAuth();
  const url = `${ENDPOINT}/api/token_exchange/exchange`;
  return apiClient.post(url, data);
};

const goBindAECard = async (data) => {
  const apiClient = createApiWithAuth();
  const url = `${ENDPOINT}/api/tappay/cards`;
  return apiClient.post(url, data);
};

const checkCustosMember = async () => {
  const apiClient = createApiWithAuth();
  const url = `${ENDPOINT}/api/v1/rental_cars/check_custos_member`;
  return apiClient.get(url);
};

const getTeslaToken = async (data) => {
  const apiClient = createApiWithAuth();
  const url = `${ENDPOINT}/api/rental_cars/owners/tesla_token`;
  return apiClient.post(url, data);
};

const postMessageToUcaht = async (data) => {
  axios.post("https://xchat.udrive.city/api/handleConvsMessage", data);
};

const googleMapApiSearch = async (input) => {
  const apiClient = createApiWithAuth();
  const url = `${ENDPOINT}/api/v1/map/place_autocomplete?input=${input}
  `;
  return apiClient.get(url);
};

const getMyMgmData = async () => {
  const apiClient = createApiWithAuth();
  const url = `${ENDPOINT}/api/v1/rental_cars/mgm`;
  return apiClient.get(url);
};

const useReferralCode = async (data) => {
  const apiClient = createApiWithAuth();
  const url = `${ENDPOINT}/api/v1/rental_cars/mgm`;
  return apiClient.post(url, data);
};

const readReferralNotify = async (data) => {
  const apiClient = createApiWithAuth();
  const url = `${ENDPOINT}/api/v1/rental_cars/mgm/${data.referral_id}`;
  return apiClient.put(url, data);
};

const getOneTimeToken = async (data) => {
  const apiClient = createApiWithAuth();
  const url = `${ENDPOINT}/api/token_exchange/generate_token`;

  return apiClient.post(url, data);
};

const getNewYearQuote = async (data) => {
  const apiClient = createApiWithAuth();
  const url = `${ENDPOINT}/api/rental_cars/settings`;
  return apiClient.get(url);
};

const api = {
  readReferralNotify,
  getMyMgmData,
  googleMapApiSearch,
  getPlaceAutoComplete,
  getPassCode,
  getToken,
  getCars,
  getBestMatchCar,
  getOrders,
  getAmount,
  bookCar,
  getMyRecentOrder,
  getMyOrder,
  updateMyOrder,
  getMyContract,
  getMyIdStatus,
  updateIdentification,
  payCarOrder,
  payFinalCarOrder,
  repayCarOrder,
  cancelCarOrder,
  getUnpaidFees,
  payUnpaidFees,
  getMyHistory,
  getMyProfile,
  updateMyProfile,
  setPaymentMethod,
  addCard,
  addNewCard,
  deleteCard,
  getPosts,
  getPostDetail,
  getJkopayUrl,
  getLinepayUrl,
  getLinepayRegKey,
  deleteLinepay,
  deleteJkopayRedKey,
  updateImage,
  getMyCars,
  getMyCarsDashbaord,
  getMyCarsOrders,
  getCouponData,
  getCountry,
  getAllOwnerSetting,
  getDeliveryZone,
  removePlate,
  createOwnerUse,
  getOwnerUseRecord,
  deleteOwnerUseRecord,
  getCouponList,
  receiveCoupon,
  getBannerList,
  bindCardWith3D,
  verifyIdentification,
  updateOcrData,
  getGroupPrice,
  getPostTerms,
  exchangeTempToken,
  goBindAECard,
  checkCustosMember,
  getTeslaToken,
  postMessageToUcaht,
  useReferralCode,
  getOneTimeToken,
  getNewYearQuote,
};

export default api;
