import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import GCPImage from "components/GCPImage";
import { addMinutes, format, isBefore } from "date-fns";
import { useSession } from "hooks/useSession";
import CouponIcon from "images/icons/CouponIcon";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import api from "store/api";
import appStore from "../../store";
import MainButton from "../MainButton";
import SwitchLang from "./SwitchLang";
import SwitchLangBtn from "./SwitchLangBtn";

const MyOrderBtn = ({ toolTip, pendingOrderList }) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { locale } = router;
  const isTw = locale === "zh-TW";
  const { member, isShowMyOrderTooltip } = appStore;

  return (
    <Box pos="relative">
      <Link
        href={{
          pathname: "/account",
          query: router.query,
        }}
      >
        <a>
          <Flex
            align="center"
            justify="center"
            px="15px"
            py="8px"
            textAlign="center"
            fontSize={{ base: "12px", md: "14px" }}
            borderRadius="30px"
            fontWeight="bold"
            color={{ base: "#fff", md: "#333" }}
            bgColor={{ base: "#333", md: "#eee" }}
            border="none"
          >
            <Text mr="2px">{t("myOrder")}</Text>
            {pendingOrderList?.length > 0 && (
              <Text
                w="16px"
                h="16px"
                fontSize="12px"
                bg="#D42828"
                color="white"
                rounded="100%"
              >
                {pendingOrderList.length}
              </Text>
            )}
          </Flex>
        </a>
      </Link>
      {pendingOrderList?.length > 0 && isShowMyOrderTooltip && (
        <Box
          pos="absolute"
          bottom="-30px"
          right="0"
          onClick={() => {
            appStore.isShowMyOrderTooltip = false;
          }}
        >
          <Text
            bg="black"
            p="2px 8px"
            borderRadius="4px"
            whiteSpace="nowrap"
            color="white"
            fontSize="14px"
            lineHeight="20px"
          >
            {toolTip}
            <Box
              position="absolute"
              top="-8px"
              left="80%"
              width="0"
              height="0"
              borderLeft="6px solid transparent"
              borderRight="6px solid transparent"
              borderBottom="8px solid black"
              transform="translateX(-50%)"
            />
          </Text>
        </Box>
      )}
    </Box>
  );
};

function MainHeader() {
  const router = useRouter();
  const { locale } = router;
  const logo = locale === "zh-TW" ? "logo_h_zh-TW.svg" : "logo_h.svg";
  const [tooltipText, setTooltipText] = useState("");
  const [pendingOrderList, setPendingOrderList] = useState([]);
  const session = useSession();
  const { t } = useTranslation("common");

  useEffect(() => {
    if (session.token) {
      if (router.pathname === "/login") {
        delete router.query["callback_url"];
        router.push({
          pathname: "/",
          query: router.query,
        });
      }
      Promise.all([
        api.getMyIdStatus(),
        api.getMyRecentOrder(),
        api.getMyMgmData(),
      ]).then((resps) => {
        const _pendingOrderList = resps[1]?.data?.data?.filter(
          (order) =>
            order.payment_status === "unpaid" &&
            isBefore(
              new Date(),
              addMinutes(
                new Date(
                  // Safari date format issues
                  `${order.created_at.replace(/-/g, "/")} UTC`
                ),
                30
              )
            )
        );
        setPendingOrderList(_pendingOrderList);

        if (_pendingOrderList?.length > 0) {
          appStore.isShowMyOrderTooltip = true;
          const date = format(
            addMinutes(
              new Date(
                // Safari date format issues
                `${_pendingOrderList[0].created_at.replace(/-/g, "/")} UTC`
              ),
              30
            ),
            "HH:mm"
          );
          const localDateString = date.toLocaleString();

          setTooltipText(
            `${t("MyOrder_tooltips_countDown_1")} ${localDateString} ${t(
              "MyOrder_tooltips_countDown_2"
            )}`
          );
        } else {
          appStore.isShowMyOrderTooltip = false;

          // KEEP: 下一階段判斷身份驗證會用到
          // if (resps[1]?.data?.data?.length > 0) {
          //   if (
          //     resps[0].data.id_card_status !== "success" ||
          //     resps[0].data.driver_license_status !== "success"
          //   ) {
          //     setTooltipText(t("MyOrder_tooltips_verify"));
          //   }
          // } else {
          //   appStore.isShowMyOrderTooltip = false;
          // }
        }

        if (resps[2]?.data?.new_referral_id) {
          appStore.isGetInviteCouponModalVisible = true;
        }
      });
    }
  }, [session.token, t]);
  return (
    <Box
      as="nav"
      w="100%"
      pos="fixed"
      zIndex="999"
      top="0"
      bgColor="#fff"
      left="0"
      boxShadow="0px 2px 4px 2px rgba(0, 0, 0, 0.08)"
    >
      <Flex
        w="100%"
        h={{ base: "50px", lg: "72px" }}
        justify="center"
        position="relative"
        px="20px"
      >
        <MobileNav toolTip={tooltipText} pendingOrderList={pendingOrderList} />
        <Flex
          width={{ base: "100%", lg: "1200px" }}
          align="center"
          justify={{ base: "center", lg: "space-between" }}
        >
          <Stack
            cursor="pointer"
            direction={{ base: "column", lg: "row" }}
            spacing={{ base: "5px", lg: "12px" }}
            align="center"
            justify="center"
            onClick={() => {
              delete router.query["model"];
              delete router.query["group"];
              delete router.query["order_id"];
              router.push({
                pathname: "/",
                query: router.query,
              });
            }}
          >
            <Box width={{ base: "170px", lg: "243px" }}>
              <GCPImage src={`/images/brand/logos/${logo}`} alt="Logo" />
            </Box>
          </Stack>
          <DesktopNav
            toolTip={tooltipText}
            pendingOrderList={pendingOrderList}
          />
        </Flex>
      </Flex>
    </Box>
  );
}

export default MainHeader;

const MobileContent = ({ isOpen, onClose }) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { locale } = router;

  const NAV_ITEMS = [
    {
      id: "home",
      label: t("homePage"),
      href: {
        pathname: "/",
      },
    },
    {
      id: "about",
      label: t("AboutUs_sec1_title"),
      href: {
        pathname: "/about",
      },
    },
    {
      id: "info",
      label: t("rentPolicyAndInsurance"),
      href: {
        pathname:
          locale === "en" ? process.env.NEXT_PUBLIC_EN_CONTRACT_URL : "/info",
      },
    },
    {
      id: "blog",
      label: t("newPost"),
      href: {
        pathname: "/blog",
      },
    },
    {
      id: "homeQA",
      label: t("Q&A"),
      href: {
        pathname: "/",
        hash: "qa",
      },
    },
    {
      id: "partner",
      label: (
        <HStack align="start">
          <Text>{t("Header_invite")}</Text>
          <Text
            bgColor="#FF6F6F"
            fontSize="12px"
            px="5px"
            fontWeight="bold"
            rounded="6px"
          >
            new
          </Text>
        </HStack>
      ),
      href: {
        pathname: "/invite",
      },
    },
  ].filter(({ id }) => {
    return locale === "en"
      ? id === "home" || id === "info" || id === "about"
      : true;
  });
  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="xs">
      <DrawerOverlay />
      <DrawerContent bgColor="#363740">
        <CloseIcon
          color="white"
          position="absolute"
          top="20px"
          left="20px"
          onClick={onClose}
          cursor="pointer"
        />
        <DrawerBody color="white">
          <Flex direction="column" align="flex-start" mt="50px">
            {NAV_ITEMS.map((item) => (
              <Link
                key={item.href.pathname}
                href={{
                  ...item.href,
                  query: router.query,
                }}
                onClick={onClose}
                left="20px"
              >
                <a
                  onClick={onClose}
                  target={locale === "en" && item.id === "info" ? "_blank" : ""}
                >
                  <Text py="10px" fontSize="20px" color="white" border="none">
                    {item.label}
                  </Text>
                </a>
              </Link>
            ))}
            <Link
              href={{
                pathname: "/account/coupon",
                query: router.query,
              }}
              onClick={onClose}
            >
              <a onClick={onClose}>
                <Text py="10px" fontSize="20px" color="white" border="none">
                  {t("addCoupon")}
                </Text>
              </a>
            </Link>
            <SwitchLang />
            {/* Mobile Bottom Btns */}
            <Box mt="50px" pos="absolute" left="0" bottom="0" w="100%">
              <Box w="100%" px="20px" py="20px">
                <MainButton
                  w="100%"
                  fontSize="18px"
                  bgColor="#111"
                  border="none"
                  rounded="10px"
                  onClick={() => {
                    onClose();
                    router.push({
                      pathname: "/station",
                      query: router.query,
                    });
                  }}
                >
                  {t("orderTeslaNow")}
                </MainButton>
              </Box>
              <Flex w="100%" justify="center" h="50px">
                <Link
                  href={{
                    pathname: "/",
                    hash: "qa",
                    query: router.query,
                  }}
                >
                  <a onClick={onClose}>
                    <Box
                      w="130px"
                      textAlign="center"
                      py="5px"
                      fontSize="13px"
                      borderRadius="30px"
                      fontWeight="bold"
                      color="#333"
                      bgColor="#fff"
                      border="none"
                      display="inline-block"
                    >
                      {t("Q&A")}
                    </Box>
                  </a>
                </Link>
              </Flex>
            </Box>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const MobileNav = ({ toolTip, pendingOrderList }) => {
  const { t } = useTranslation("common");
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { member, isShowMyOrderTooltip } = appStore;
  const router = useRouter();

  return (
    <>
      <Box
        onClick={onOpen}
        cursor="pointer"
        display={{ lg: "none" }}
        position="absolute"
        left="20px"
        top="9px"
        bg="transparent"
      >
        <HamburgerIcon color="black" w={8} h={8} />
      </Box>
      <Box
        pos="absolute"
        right="7px"
        top="7px"
        display={{ base: "block", lg: "none" }}
        onClick={() => {
          console.log("click");
          appStore.isShowMyOrderTooltip = false;
        }}
      >
        {member.id ? (
          <MyOrderBtn toolTip={toolTip} pendingOrderList={pendingOrderList} />
        ) : (
          <Link
            href={{
              pathname: "/login",
              query: router.query,
            }}
          >
            <a>
              <Flex
                justify="center"
                px="15px"
                py="8px"
                textAlign="center"
                fontSize="12px"
                borderRadius="30px"
                fontWeight="bold"
                color="#fff"
                bgColor="#333"
                border="none"
                display="inline-block"
              >
                <Text as="span">{t("login")}</Text>
              </Flex>
            </a>
          </Link>
        )}
      </Box>
      <MobileContent onOpen={onOpen} isOpen={isOpen} onClose={onClose} />
    </>
  );
};

const DesktopNav = ({ toolTip, pendingOrderList }) => {
  const { t } = useTranslation("common");
  const path = useRouter().pathname;
  const { member, isShowMyOrderTooltip } = appStore;
  const session = useSession();
  const router = useRouter();
  const { locale } = router;
  const isTw = locale === "zh-TW";

  useEffect(() => {
    if (session.isLoading) return;
    if (session.token) {
      api.getMyProfile().then((resp) => {
        if (resp.status === 200) {
          appStore.member = resp.data.content;
        }
      });
    }
  }, [session.isLoading]);

  return (
    <>
      <Flex
        display={{ base: "none", lg: "flex" }}
        flex="1"
        justify="space-around"
      >
        <nav>
          <HStack spacing="20px">
            <Link
              href={{
                pathname: "/about",
                query: router.query,
              }}
            >
              <a>
                <Text
                  py="20px"
                  fontSize="sm"
                  color={path === "/about" ? "gray.400" : "black"}
                >
                  {t("AboutUs_sec1_title")}
                </Text>
              </a>
            </Link>
            <Center height="50px">
              <Divider orientation="vertical" color="Black" />
            </Center>
            {isTw == false && (
              <Link
                href={{
                  pathname: process.env.NEXT_PUBLIC_EN_CONTRACT_URL,
                }}
              >
                <a target="_blank">
                  <Text py="20px" fontSize="sm" color="black">
                    {t("rentPolicyAndInsurance")}
                  </Text>
                </a>
              </Link>
            )}
            {isTw && (
              <>
                <Link
                  href={{
                    pathname: "/info",
                    query: router.query,
                  }}
                >
                  <a>
                    <Text
                      py="20px"
                      fontSize="sm"
                      color={path === "/info" ? "gray.400" : "black"}
                    >
                      {t("rentPolicyAndInsurance")}
                    </Text>
                  </a>
                </Link>
                <Center height="50px">
                  <Divider orientation="vertical" color="Black" />
                </Center>
                <Link
                  href={{
                    pathname: "/blog",
                    query: router.query,
                  }}
                >
                  <a>
                    <Text
                      py="20px"
                      fontSize="sm"
                      color={path === "/blog" ? "gray.400" : "black"}
                    >
                      {t("newPost")}
                    </Text>
                  </a>
                </Link>
                {/* <Center height="50px">
                  <Divider orientation="vertical" color="Black" />
                </Center> */}
                {/* <Link
                  href={{
                    pathname: "/join",
                    query: router.query,
                  }}
                >
                  <a>
                    <Text
                      py="20px"
                      fontSize="sm"
                      color={path === "/join" ? "gray.400" : "black"}
                    >
                      {t("joinUdrive")}
                    </Text>
                  </a>
                </Link> */}
                <Center height="50px">
                  <Divider orientation="vertical" color="Black" />
                </Center>
                <Link
                  href={{
                    pathname: "/invite",
                    query: router.query,
                  }}
                >
                  <a>
                    <Text
                      py="20px"
                      fontSize="sm"
                      color={path === "/invite" ? "gray.400" : "black"}
                    >
                      {t("Header_invite")}
                    </Text>
                  </a>
                </Link>
              </>
            )}

            <Center height="50px">
              <Divider orientation="vertical" color="Black" />
            </Center>
            {isTw ? <SwitchLangBtn /> : <SwitchLang />}
          </HStack>
        </nav>
      </Flex>
      <HStack display={{ base: "none", lg: "flex" }}>
        <Link
          href={{
            pathname: "/account/coupon",
            query: router.query,
          }}
        >
          <a>
            <Flex
              justify="center"
              px="15px"
              py="8px"
              textAlign="center"
              fontSize="14px"
              borderRadius="30px"
              fontWeight="bold"
              display="inline-block"
              borderWidth="2px"
            >
              <CouponIcon size="16px" mb="1px" />
              <Text as="span" ml="6px">
                {t("addCoupon")}
              </Text>
            </Flex>
          </a>
        </Link>
        {member.id ? (
          <MyOrderBtn toolTip={toolTip} pendingOrderList={pendingOrderList} />
        ) : (
          <Link
            href={{
              pathname: "/login",
              query: router.query,
            }}
          >
            <a>
              <Flex
                justify="center"
                px="15px"
                py="8px"
                textAlign="center"
                fontSize="14px"
                borderRadius="30px"
                fontWeight="bold"
                color="#333"
                bgColor="#eee"
                border="none"
                display="inline-block"
              >
                <Text as="span">{t("login")}</Text>
              </Flex>
            </a>
          </Link>
        )}
      </HStack>
    </>
  );
};
