import { Box, Flex, Text } from "@chakra-ui/react";
import { withTranslation } from "next-i18next";
import React, { Component } from "react";
import GCPImage from "./GCPImage";
import { getUA, deviceType, osName } from "react-device-detect";
import appStore from "store";
import axios from "axios";
import { DateObject } from "react-multi-date-picker";

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error,
      errorInfo,
    });

    const { member } = appStore;

    const body = {
      error_title: "官網發生錯誤",
      error_message: `${error.message}(${window?.location?.href})`,
      error_location: errorInfo.componentStack,
      platform: "udrive",
      type: "udrive_web_crash",
      additional_info: {
        report_user_info: {
          user_id: member?.id,
          user_name: member?.fullname,
          user_phone: member?.phone,
          user_payment_method: member?.payment_method,
          device_info: {
            user_agent: getUA,
            device_type: deviceType,
            os_name: osName,
          },
        },
        appStore: {
          address: appStore.address,
          amount: appStore.amount,
          avaiableCarGroups: appStore.avaiableCarGroups,
          availableCarIds: appStore.availableCarIds,
          cityUnion: appStore.cityUnion,
          colorSelected: appStore.colorSelected,
          configs: appStore.configs,
          isAirport: appStore.isAirport,
          isAirportReturn: appStore.isAirportReturn,
          isSameAddress: appStore.isSameAddress,
          modelsFilter: appStore.modelsFilter,
          selectedCarIds: appStore.selectedCarIds,
          selectedCity: appStore.selectedCity,
          selectedReturnCity: appStore.selectedReturnCity,
          selectedZone: appStore.selectedZone,
          selectedReturnZone: appStore.selectedReturnZone,
          selectedZoneId: appStore.selectedZoneId,
          selectedReturnZoneId: appStore.selectedReturnZoneId,
          selectedDays: [
            new DateObject(appStore.selectedDays[0]),
            new DateObject(appStore.selectedDays[1]),
          ],
          selectedEndClock: appStore.selectedEndClock,
          selectedStartClock: appStore.selectedStartClock,
        },
      },
    };

    try {
      // 如果在localhost，不要發送錯誤報告
      if (window.location.hostname === "localhost") return;
      axios.post("https://xchat.udrive.city/api/reportBug", body);
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { t } = this.props;
    if (this.state.hasError) {
      return (
        <Flex
          w="100vw"
          h="100vh"
          flexDir="column"
          align="center"
          justify="center"
        >
          <GCPImage src="/images/500.jpg" alt="500" w="180px" />
          <Text fontSize="24px" fontWeight="700" pt="24px">
            {t("Error500_title")}
          </Text>
          <Text fontSize="14px" fontWeight="500" color="#888">
            {t("Error500_infotext")}
          </Text>
        </Flex>
      );
    }
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
