import { Box, Center, Flex, Spinner } from "@chakra-ui/react";
import GCPImage from "components/GCPImage";
import { useSession } from "hooks/useSession";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import appStore from "store";
import { sendAppierEvent } from "lib/appier";

const IframeContent = ({ isLoading, setIsLoading, iframeRef, iframeSrc }) => {
  const session = useSession();

  return (
    <>
      {isLoading && (
        <Center w="100%" h="100%" pos="relative" bgColor="#fff">
          <Spinner />
        </Center>
      )}
      {iframeSrc && (
        <iframe
          ref={iframeRef}
          onLoad={() => {
            console.log("onLoad");
            iframeRef.current.contentWindow.postMessage(
              { udrive_token: session.token },
              iframeSrc
            );
            setIsLoading(false);
          }}
          src={iframeSrc}
          width="100%"
          height="100%"
          style={{ border: "none", overflow: "hidden" }}
        ></iframe>
      )}
    </>
  );
};

const ButtonIcon = ({ onOpen, onClose, isClicked }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { isHelperOpen } = appStore;
  const session = useSession();
  const { token } = session;

  const isBlogPath =
    router.pathname.includes("blog") && !router.asPath.includes("blog/379");

  const isAboutPath = router.pathname.includes("about");

  return (
    !isBlogPath &&
    !isAboutPath && (
      <Box
        zIndex="999"
        cursor="pointer"
        pos="fixed"
        bottom={
          router.pathname.includes("rent/order") ||
          router.pathname.includes("rent/nocar") ||
          router.pathname.includes("rent/carInfo") ||
          router.pathname.includes("rent/newYearCarInfo") ||
          router.pathname.includes("rent/summerCarInfo")
            ? { base: "100px", md: "20px" }
            : router.pathname.includes("invite")
            ? token
              ? { base: "160px", md: "20px" }
              : { base: "100px", md: "20px" }
            : "20px"
        }
        right="20px"
        onClick={() => {
          if (isHelperOpen) {
            onClose();
          } else {
            onOpen();
          }
        }}
      >
        {!isClicked && (
          <Flex
            pos="absolute"
            top="0"
            transform="translateY(calc(-100% - 5px))"
            fontSize="12px"
            bgColor="#fff"
            w="100px"
            lineHeight="1.2"
            minH="20px"
            right="0"
            textAlign="center"
            rounded="5px"
            fontWeight="bold"
            align="center"
            justify="center"
          >
            {t("uchat_tips")}
          </Flex>
        )}
        <GCPImage
          boxShadow="0px 4px 8px 4px rgba(0, 0, 0, 0.08)"
          rounded="full"
          alt="icon"
          src="/images/chatIcon.svg"
          w="60px"
        />
      </Box>
    )
  );
};

const UchatButton = () => {
  const { isHelperOpen, member } = appStore;
  const iframeRef = useRef(null);
  const router = useRouter();
  const { locale } = router;
  const [iframeSrc, setIframeSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isClicked, setIsClicked] = useState(false);
  const session = useSession();

  useEffect(() => {
    if (session.isLoading) return;
    if (session.token) {
      if (typeof window !== "undefined") {
        // KEEP: for dev
        if (window?.location?.href?.includes("dev")) {
          setIframeSrc("https://xchat.udrive.city/customer?dev");
        } else {
          setIframeSrc("https://xchat.udrive.city/customer");
        }
      }
    }
  }, [locale, session.token, session.isLoading]);

  useEffect(() => {
    if (iframeSrc && iframeRef.current) {
      iframeRef.current.contentWindow.postMessage(
        { udrive_token: session.token },
        iframeSrc
      );
    }
  }, [router, iframeSrc, session.token, isHelperOpen]);

  useEffect(() => {
    if (isHelperOpen && router.isReady) {
      sendAppierEvent("click_helper", {
        user_id: member?.id,
        phoneNo: member?.phone,
        user_name: member?.fullname,
        page_name: router.pathname,
      });
    }
  }, [isHelperOpen, router.isReady]);

  useEffect(() => {
    if (!router.isReady || session.isLoading) return;
    if (router?.query?.helper && session.token) {
      delete router.query["helper"];
      if (router.query.hasOwnProperty("uchat")) {
        delete router.query["uchat"];
        appStore.isHelperOpen = true;
      }
      appStore.isHelperOpen = true;
      router.replace({ pathname: router.pathname, query: router.query });
    }
  }, [router, router.isReady, session.isLoading]);

  useEffect(() => {
    if (isHelperOpen) {
      if (!session.token && !session.isLoading) {
        appStore.isHelperOpen = false;
        router.push({
          pathname: "/login",
          query: { ...router.query, helper: true },
        });
        return;
      }
    } else {
      return;
    }
  }, [isHelperOpen, router, session.isLoading, session.token]);

  const onClose = () => {
    appStore.isHelperOpen = false;
    setIsClicked(true);
  };

  const onOpen = () => {
    appStore.isHelperOpen = true;
  };

  return (
    <>
      {/* Button */}
      {!isHelperOpen && (
        <ButtonIcon onOpen={onOpen} onClose={onClose} isClicked={isClicked} />
      )}
      {/* üchat */}
      <Box
        id="mobile-chat"
        pos="fixed"
        w="100%"
        h="100%"
        maxW={{ base: "initial", md: "380px" }}
        maxH={{ base: "initial", md: "650px" }}
        boxShadow={{ base: "none", md: "0px 4px 8px 4px rgba(0, 0, 0, 0.08)" }}
        right={{ base: "0", md: "20px" }}
        bottom={{ base: "0", md: router.pathname === "/" ? "20px" : "20px" }}
        opacity={isHelperOpen ? "1" : "0"}
        transition="0.3s"
        pointerEvents={isHelperOpen ? "auto" : "none"}
        transform={isHelperOpen ? "scale(1)" : "scale(0.4)"}
        transformOrigin="right bottom"
        zIndex={isHelperOpen ? "9999" : "-1"}
        rounded={{ base: "none", md: "10px" }}
        overflow={{ base: "initial", md: "hidden" }}
      >
        <Center
          pos="absolute"
          top="10px"
          right="12px"
          w="35px"
          h="35px"
          cursor="pointer"
          onClick={() => {
            document.body.style.overflow = "scroll";
            onClose();
          }}
          fontSize="26px"
        >
          ×
        </Center>
        <IframeContent
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          iframeRef={iframeRef}
          iframeSrc={iframeSrc}
        />
      </Box>
    </>
  );
};
export default UchatButton;
