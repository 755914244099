import { Image } from "@chakra-ui/react";

const GCPImage = ({ src, alt = "img", ...props }) => {
  let url = src;
  if (src && src[0] !== "/") {
    url = `/${src}`;
  }
  return (
    <Image
      src={`${process.env.NEXT_PUBLIC_GCP_IMAGE_URL}${url}`}
      alt={alt}
      {...props}
    />
  );
};
export default GCPImage;
