export const pageview = (url) => {
  if (window && window.gtag) {
      window.gtag('config', 'G-SL0F7MGE4X', {
          page_path: url,
      })
  }
}

// Category	String	是	把事件做個分類：像首頁的 banner	Banner
// Action	String	是	這個事件被觸發的動作：像點擊,加到購物車	廣告點擊
// Label	String	否	這個事件的子分類：像 左邊600×300	24h購物
// Value	Number	否	這個事件的價值：10	10
// Example:
// gtag('event', 'Nav Click', {
//   'event_category': 'Click',
//   'event_label': '網站分析/GA教學',
//   'value': 500
//  });
export const event = ({ action, params = {} }) => {
  window.gtag('event', action, params)
}

export const handleUtm = () => {
  return utm;
}