import { useEffect } from "react";
import { useRouter } from "next/router";
import { useSession } from "hooks/useSession";
import api from "store/api";
import appStore from "store";
import toastErrorAndReport from "lib/toastErrorAndReport";

export default function AuthWrapper({ children }) {
  const router = useRouter();
  const session = useSession();
  useEffect(() => {
    console.log("session.isLoading", session.isLoading);
    if (session.isLoading) {
      return;
    }
    if (session.token) {
      console.log("Get my profile");
      api
        .getMyProfile()
        .then((resp) => {
          if (resp.status === 200) {
            appStore.member = resp.data.content;
          } else {
            appStore.member = {};
            session.clearToken();
          }
        })
        .catch((error) => {
          appStore.member = {};
          session.clearToken();
        });
    }
  }, [session.isLoading, session.token]);

  useEffect(() => {
    const token = router?.query?.token;
    if (token) {
      api
        .exchangeTempToken({
          onetime_token: token,
        })
        .then((resp) => {
          // TODO: 需要提醒後端如果 token 錯誤的話要回傳 401
          if (resp?.data?.status === "success") {
            session.setToken(resp.data.token);
          } else {
            toastErrorAndReport(resp.data.message);
          }
          const query = { ...router.query };
          delete query.token;
          router.replace({
            pathname: router.pathname,
            query,
          });
        });
    }
  }, [router?.query?.token]);
  return children;
}
