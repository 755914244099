import { CheckIcon, EmailIcon, UnlockIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useRecaptcha } from "hooks/useRecaptcha";
import { useSession } from "hooks/useSession";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import * as ga from "../../lib/analytics";
import appStore from "../../store";
import api from "../../store/api";
import gasApi from "../../store/gasApi";
import MainButton from "../MainButton";

const RegisterForm = () => {
  const { t } = useTranslation("common");
  const session = useSession();
  const router = useRouter();
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState();
  const [loading, setLoading] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [countryCode, setCountryCode] = useState("886");
  const {
    token: reCaptchaToken,
    reset: resetRecaptchaToken,
    execute: executeRecaptcha,
  } = useRecaptcha();

  useEffect(() => {
    if (session.isLoading) return;
    if (session.token) {
      setStepIndex(2);
    }
  }, [session.isLoading, session.token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    let phoneNumber = phone.replace(`${countryCode}`, "");

    if (phoneNumber.length === 0) {
      return;
    }

    if (countryCode === "886" && phoneNumber.length === 9) {
      phoneNumber = phone.replace(`${countryCode}`, "0");
    }
    if (countryCode === "81" && phoneNumber.length === 10) {
      phoneNumber = phone.replace(`${countryCode}`, "0");
    }

    api
      .getPassCode({
        google_recaptcha_token: reCaptchaToken,
        country_code: countryCode,
        phone_number: phoneNumber,
        verify_type: "invisible",
      })
      .then(() => {
        setStepIndex(1);
      })
      .catch((error) => {
        const errorCode = error?.message;
        switch (errorCode) {
          case "smsCoolDown":
            const seconds = error.cooldown;
            alert(`${t("phoneError1-1")}${seconds}${t("phoneError1-2")}`);
            return;
          case "smsPhoneNumberError":
            alert(t("phoneError2"));
            return;
          default:
            alert(t("phoneError3"));
            break;
        }
      });

    gasApi
      .postMember({
        phone,
        email,
        utm_campaign: router?.query?.utm_campaign || "",
        utm_source: router?.query?.utm_source || "",
        utm_medium: router?.query?.utm_medium || "",
        referral: router?.query?.referral || "",
        partner: router?.query?.partner || "",
        others: router.query ? JSON.stringify(router.query) : "",
      })
      .then(() => {
        fbq("track", "CompleteRegistration");
        ga.event({
          action: "login",
        });
      })
      .catch();
    resetRecaptchaToken();
    executeRecaptcha();
  };

  return (
    <>
      <VStack fontSize="14px" w="100%" mt="15px">
        <HStack justify="center">
          <CheckIcon color="#2CD165" />
          <Text w="230px" textAlign="left" opacity={true ? 1 : 0.5}>
            {t("discountContent1")}
          </Text>
        </HStack>
        <HStack mt="30px" justify="center">
          <CheckIcon color="#2CD165" />
          <Text w="230px" textAlign="left" opacity={true ? 1 : 0.5}>
            {t("discountContent2")}
          </Text>
        </HStack>
        <HStack mt="30px" justify="center">
          <CheckIcon color="#2CD165" />
          <Text w="230px" textAlign="left" opacity={true ? 1 : 0.5}>
            {t("discountContent3")}
          </Text>
        </HStack>
      </VStack>
      <Box
        w="100%"
        maxW="500px"
        py="5px"
        overflowY="visible"
        height="100%"
        mt="15px"
      >
        <Flex textAlign="center">
          {stepIndex == 0 && (
            <Box w="100%">
              <VStack w="300px" mx="auto" spacing="10px">
                <InputGroup>
                  <InputLeftElement h="50px" pointerEvents="none">
                    <EmailIcon color="gray.500" />
                  </InputLeftElement>
                  <Input
                    type="email"
                    placeholder="user@udrive.city"
                    h="50px"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
                <InputGroup>
                  <PhoneInput
                    inputStyle={{
                      height: "50px",
                      width: "100%",
                      backgroundColor: "inherit",
                    }}
                    buttonStyle={{ backgroundColor: "inherit" }}
                    enableSearch={true}
                    countryCodeEditable={false}
                    autoFormat={true}
                    country={"tw"}
                    value={countryCode}
                    masks={{
                      tw: "....-...-...",
                      jp: "...-....-....",
                    }}
                    onChange={(phone, code) => {
                      setPhone(phone);
                      setCountryCode(code.dialCode);
                    }}
                  />
                </InputGroup>
              </VStack>
              <MainButton
                disabled={!(phone && email !== "")}
                isLoading={loading}
                border="none"
                rounded="30px"
                lineHeight="1"
                fontSize="15px"
                w="200px"
                height="45px"
                mt="15px"
                bgColor="rgba(23, 26, 32, 0.8)"
                hasIcon={true}
                onClick={handleSubmit}
              >
                {t("loginAndReceiveDiscount")}
              </MainButton>
            </Box>
          )}
          {stepIndex == 1 && (
            <Box w="100%">
              <VStack w="300px" mx="auto" mt="20px" spacing="10px">
                <InputGroup>
                  <InputLeftElement h="50px" pointerEvents="none">
                    <UnlockIcon color="gray.500" />
                  </InputLeftElement>
                  <Input
                    type="tel"
                    placeholder={t("passCode")}
                    h="50px"
                    onChange={(e) => setCode(e.target.value)}
                  />
                </InputGroup>
              </VStack>
              <MainButton
                disabled={code && code.length < 4}
                isLoading={loading}
                border="none"
                rounded="30px"
                lineHeight="1"
                fontSize="15px"
                w="200px"
                height="45px"
                mt="15px"
                bgColor="rgba(23, 26, 32, 0.8)"
                hasIcon={true}
                onClick={() => {
                  let phoneNumber = phone.replace(`${countryCode}`, "");
                  if (countryCode === "886" && phoneNumber.length === 9) {
                    phoneNumber = phone.replace(`${countryCode}`, "0");
                  }
                  if (countryCode === "81" && phoneNumber.length === 10) {
                    phoneNumber = phone.replace(`${countryCode}`, "0");
                  }
                  setLoading(true);
                  const promotion = { ...router.query };
                  delete promotion["group"];
                  delete promotion["callback_url"];
                  delete promotion["order_id"];
                  const registrationSource = JSON.stringify(promotion);
                  api
                    .getToken({
                      phoneNumber,
                      passCode: code,
                      countryCode,
                      registrationSource,
                    })
                    .then((result) => {
                      if (result.status === 200) {
                        setStepIndex(2);
                        session.setToken(result.data.token);
                        api
                          .updateMyProfile({
                            email,
                          })
                          .catch(console.log);

                        const data = { code: router.query.coupon };
                        api.receiveCoupon(data).then((resp) => {
                          if (resp.status === 200) {
                            toast.success(t("getCouponSuccess"));
                          }
                          delete router.query["coupon"];
                          router.push({
                            query: router.query,
                          });
                        });
                      }
                    })
                    .catch(console.log);
                  setLoading(false);
                }}
              >
                {t("goPass")}
              </MainButton>
            </Box>
          )}
          {stepIndex == 2 && (
            <Box w="100%">
              <Text fontSize="16px" opacity="0.5">
                {t("discountIsLoginText")}
              </Text>
              <Flex mt="25px" justify="center" align="center">
                <MainButton
                  maxW="120px"
                  mr="15px"
                  flex="1 1 50%"
                  rounded="30px"
                  lineHeight="1"
                  fontSize="15px"
                  height="45px"
                  border="1px solid rgba(23, 26, 32, 0.2)"
                  bgColor="#eee"
                  color="#333"
                  onClick={() => {
                    appStore.isRegisterModalVisible = false;
                    router.push({
                      pathname: "/account",
                      query: router.query,
                    });
                  }}
                >
                  {t("seeOrder")}
                </MainButton>
                <MainButton
                  flex="1 1 50%"
                  maxW="120px"
                  border="none"
                  rounded="30px"
                  lineHeight="1"
                  fontSize="15px"
                  height="45px"
                  bgColor="rgba(23, 26, 32, 0.8)"
                  onClick={() => {
                    appStore.isRegisterModalVisible = false;
                    router.push({
                      pathname: "/rent",
                      query: router.query,
                    });
                  }}
                >
                  {t("orderNow")}
                </MainButton>
              </Flex>
            </Box>
          )}
        </Flex>
      </Box>
    </>
  );
};

export default RegisterForm;
